import logo from './logo.svg';
import './DisplayJobs.css';
import { useEffect, useState } from 'react';
import {useTranslation, Trans}from "react-i18next";
import moment from "moment";
import 'moment/locale/de';

const momento = moment;
momento.locale('de');

function App(props) {
	

	const {options} = props; 



	const [jobs, setJobs] = useState([]);
	


	useEffect(() => {
		async function fetchJobs() {
			fetch(`${options.uri}/api/v1/jobs/other?limit=${options.limit > 10 ? 10 : options.limit}${options.order ? '&order='+options.order : ''}${options.accountId ? '&accountId=' + options.accountId : ''}`).then(function (response) {
				// The API call was successful!
				console.log('Success', response);
				return response.json();
			})
			.then((paginatedData) => {
				console.log('JOB', paginatedData);
				setJobs(paginatedData);
			}).catch(function (err) {
				// There was an error
				console.warn('Something went wrong.', err);
			});

		}

		fetchJobs();
	}, [])


  return (
<div className="bytt-jobs-display-jd">
	<div className="bytt-jd-template">
	{jobs.map((job,index)=> (
		<div className='bytt-jd-card-wrapper' key={'jobdis_' + index}>
			<div className='bytt-jd-card'>
				<div className='bytt-jd-card-body'>
					{job.types && job.types.includes('work_training') ? <span className="bytt-jd-badge">AUSBILDUNG</span> : null}
					{options.showLogo ? 
					<div className="bytt-jd-logo">
						<a href={`/stellenmarkt/${job.slug}`}>
							<img src={`${options.mediaUri}/${job?.account?.account_logo}`} alt={`Unternehmenslogo von ${job?.account?.name}`}/>
						</a>
					</div> : null}
					<h4><a href={`/stellenmarkt/${job.slug}`}>{job.name}</a></h4>
					<div className="bytt-jd-info">
						{job?.account?.external_id ? (<a href={`/${job?.account?.external_id}`}>{job.account?.name}</a>) 
						: (<span>{job.account?.name}</span>)}
						{' '}•{' '}<span>{job.employment_address?.city}</span>
					</div>
				</div>
			</div>
		</div>
	))}
	</div>
	{options?.showMoreButton == true ?
	<div className='bytt-jd-controls'>
		<a href="https://www.eppingen.org/stellenmarkt">Mehr Stellenangebote</a>
	</div> : null}
</div>);
}

export default App;
