import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const defaultOptions = {
    uri: 'https://shik-engine.bytt.app',
    mediaUri: 'https://shik-media.bytt.app',
    limit: 10,
    showMoreButton: true,
    showLogo: false,
    accountId: false,
    order: false
};

// Function to create and render an App instance
function createAppInstance(elementId, customOptions = {}) {
    const options = { ...defaultOptions, ...customOptions };
    const root = ReactDOM.createRoot(document.getElementById(elementId));
    root.render(
        <React.StrictMode>
            <App options={options} />
        </React.StrictMode>
    );
}

// Check for global configuration
if (window.b313 && window.b313.jobsDisplayInstances) {
    // Create instances based on global configuration
    window.b313.jobsDisplayInstances.forEach(instance => {
        createAppInstance(instance.rootElement, instance);
    });

} else if (window.b313 && window.b313.jobsDisplay) {
	// Create single instance based on global configuration
	createAppInstance('root', window.b313.jobsDisplay);
}

reportWebVitals();